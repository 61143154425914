import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import 'moment/locale/fr';
import 'moment/locale/ru';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';


class DimensionsComponent extends Component {

  render() {
    const {
      weight,
      width,
      height,
      length,
      codCost,
      assessedCost,
      handleChange
    } = this.props;

    return (
      <Grid container spacing={8}>
        <Grid item xs={12} sm={4} md={2} lg={2}>
          <TextField
            margin="dense"
            name="height"
            value={height}
            variant="outlined"
            label="Высота, см"
            placeholder="100"
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange} 
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={2}>
          <TextField
            margin="dense"
            name="length"
            value={length}
            variant="outlined"
            label="Длина, см"
            placeholder="80"
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange} 
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={2}>
          <TextField
            margin="dense"
            name="width"
            variant="outlined"
            value={width}
            label="Ширина, см"
            placeholder="30"
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange} 
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={2}>
          <TextField
            margin="dense"
            name="weight"
            value={weight}
            variant="outlined"
            label="Общий вес, кг"
            placeholder="1"
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true,
              
            }}
            onChange={handleChange} 
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={2}>
          <TextField
            margin="dense"
            name="assessedCost"
            value={assessedCost}
            variant="outlined"
            label="Объявленная стоимость"
            placeholder="1000"
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true,
              
            }}
            onChange={handleChange} 
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={2}>
          <TextField
            margin="dense"
            name="codCost"
            value={codCost}
            variant="outlined"
            label="Наложенная стоимость"
            placeholder="2000"
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true,
              
            }}
            onChange={handleChange} 
          />
        </Grid>
      </Grid>
    )
  }
}

DimensionsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DimensionsComponent);