import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import isEqual from 'lodash/isEqual';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {DELIVERY_TYPES} from '../../constants';
import formatDeliveryDays from '../../tools/format-delivery-days';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import styles from './styles';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#3f51b5',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const gunnarStyle = { height: "33px"};
const gunnarCellStyle = { height: "33px", paddingLeft: '0px', paddingRight: '0px'};


class DeliveryTypesComponent extends React.Component {  
  render() {
    const {classes, tariffs, handleShowPoints} = this.props;

    return (
          <Table style={{ tableLayout: 'auto' }} padding='dense' className={classes.table}>
          <TableHead>
            <TableRow style={gunnarStyle}>
              <CustomTableCell style={gunnarStyle}>Cпособ получения</CustomTableCell>
              <CustomTableCell style={gunnarStyle}>Срок доставки</CustomTableCell>
              <CustomTableCell style={gunnarStyle}>Стоимость доставки</CustomTableCell>
              <CustomTableCell style={gunnarStyle}></CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tariffs.map((tariff, index) => {
              return (
                <TableRow key={index} style={gunnarStyle}>
                  <TableCell style={gunnarCellStyle}>{DELIVERY_TYPES[tariff.deliveryType] && DELIVERY_TYPES[tariff.deliveryType].full}</TableCell>
                  <TableCell style={gunnarCellStyle}>{formatDeliveryDays(tariff.minDays, tariff.maxDays)}</TableCell>
                  <TableCell style={gunnarCellStyle}>{tariff.deliveryCost && tariff.deliveryCost.value}</TableCell>
                  <TableCell style={gunnarCellStyle}>
                    {
                      tariff.deliveryType === 'deliveryToPoint' && (
                        <Button onClick={handleShowPoints} variant="contained" color="primary">Подробнее</Button>
                      )
                    }
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          </Table>
    );
  }
}

DeliveryTypesComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeliveryTypesComponent);
