import React, { Component } from 'react';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import AddressComponent from '../address';
import DimensionsComponent from '../dimensions';
import DeliveryTypesComponent from '../delivery-types';
import moment from 'moment';
import DialogActions from '@material-ui/core/DialogActions';
import PointsComponent from '../points';
import {toast} from 'react-toastify';
import calculationParams from '../../tools/calculation-params';
import getTariffs from '../../tools/get-tariffs';
import publicApi from '../../services/public';
import Button from '@material-ui/core/Button';
import isEqual from 'lodash/isEqual';
import Typography from '@material-ui/core/Typography';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Preloader from '../layout/page/preloader';
import styles from './styles';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
  },
  expanded: {
    margin: 'auto',
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    // marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
  },
  content: {
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanelDetails = withStyles(theme => ({
  root: {},
}))(MuiExpansionPanelDetails);

class DialogComponent extends Component {
  state = {
    apikey: "",

    deliveryType: null,

    tariffs: [],
    pointTariffs: [],

    assessedCost: "",
    codCost: "",

    deliveryDate: moment(),
    deliveryTimeFrom: moment(`${moment().utc().format('YYYY-MM-DD')} 09:00`),
    deliveryTimeTo: moment(`${moment().utc().format('YYYY-MM-DD')} 18:00`),

    isUpdated: true,

    length: "",
    height: "",
    width: "",
    weight: "",

    loading: false,

    expanded: {
      address: true,
      dimensions: true,
      date: true
    },

    address: {
      index: null,

      fias_id: "",

      city: "",
      city_fias_id: null,
      settlement_fias_id: null,

      street_fias_id: null,
      street: "",

      house: "",
      house_fias_id: "",

      apartment: "",
    },

    point: {
      id: null,
      name: "",
    },

    showPoints: false,
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(this.state.address, prevState.address) ||
      this.state.length !== prevState.length || 
      this.state.height !== prevState.height || 
      this.state.width !== prevState.width || 
      this.state.weight !== prevState.weight || 
      this.state.assessedCost !== prevState.assessedCost || 
      this.state.codCost !== prevState.codCost || 
      this.state.deliveryDate !== prevState.deliveryDate || 
      this.state.deliveryTimeFrom !== prevState.deliveryTimeFrom || 
      this.state.deliveryTimeTo !== prevState.deliveryTimeTo
    ) {
      this.setState({isUpdated: true});
    }
  }

  handleClose = () => {
    window.parent.postMessage(JSON.stringify({name: 'close'}), "*");
  };

  listener = event => {
    const origin = 'http://localhost:4044';
    // const origin = "https://widget-calculate.a1srv.ru";
    
    if (event.origin !== origin) {
      return;
    }

    const {apikey} = JSON.parse(event.data);

    this.setState({apikey});
  }

  componentDidMount() {
    if (window.addEventListener) {
      window.addEventListener("message", this.listener);
    } else {
      window.attachEvent("onmessage", this.listener);
    }
  }

  handleCity = (city, event) => {
    if (event.action === "select-option") {
      const {value, index, data: {city_fias_id, settlement_fias_id}} = city;

      this.setState({
        address: {
          ...this.state.address,
          index,
          city: value,
          city_fias_id,
          settlement_fias_id,
          street: "",
          street_fias_id: null,
          house: "",
          apartment: "",
        },
      });
    }
  }

  handleStreet = (street, event) => {
    if (event.action === "select-option") {
      const {value, data: {fias_id}} = street;

      this.setState({
        address: {
          ...this.state.address,
          house: "",
          street: value,
          street_fias_id: fias_id,
        },
      });
    }
  }

  handleHouse = (house, event) => {
    if (event.action === "select-option") {
      const {value, index} = house;
      this.setState({
        address: {
          ...this.state.address,
          index,
          house: value
        },
      })
    }
  }

  handleApartment = (event) => {
    const {name, value} = event.target;
    this.setState({
      address: {
        ...this.state.address,
        [name]: value,
      },
    });
  }

  handleDate = (name, value) => {
    this.setState({[name]: value});
  }

  handleChange = (event) => {
    const {name, value} = event.target;
    this.setState({[name]: value});
  }

  handleExpanded = (name, event, value) => {
    this.setState({
      expanded: {
        ...this.state.expanded,
        [name]: value
      }
    })
  }

  handleSubmit = () => {
    const {
      address: {
        index,
        city,
        street,
        house,
      },
      weight,
      width,
      length,
      height,
      assessedCost,
      codCost,
      apikey,
    } = this.state;

    const address = `${city}, ${street}, ${house}`;

    const params = {
      index,
      city,
      address,
      weight,
      width,
      length,
      height,
      assessedCost,
      codCost,
    }

    this.setState({loading: true});
    publicApi('calculator2', calculationParams(params), "POST", {Apikey: apikey})
      .then(json => {
        const result = json || {};

        this.setState({loading: false});

        if (result.error) {
          toast.error(result.description || 'Не удалось рассчитать доставку')
          return;
        }

        const {tariffs, pointTariffs} = getTariffs(result.tariffs);

        this.handleTariff(tariffs, pointTariffs);

        if (tariffs.length === 0) {
          toast.error('Не удалось рассчитать доставку');
          return;
        }

        this.setState({isUpdated: false});
      })
      .catch(e => {
        toast.error('Сервис временно недоступен')
        this.setState({loading: false});
      })

  }

  handleTariff = (tariffs, pointTariffs) => {
    this.setState({tariffs, pointTariffs});
  }

  handlePoint = (id, name) => {
    this.setState({point: {id, name}});
  }

  render() {
    const {classes} = this.props;
    const {
      deliveryDate,
      deliveryTimeFrom,
      deliveryTimeTo,
      height,
      weight,
      width,
      length,
      address: {
        index,
        city,
        city_fias_id,
        settlement_fias_id,
        street,
        street_fias_id,
        house,
        apartment,
      },
      assessedCost,
      codCost,
      tariffs,
      pointTariffs,
      expanded,
      loading,
      isUpdated,
      showPoints,
      point,
    } = this.state;

    return (
      <Dialog
        fullWidth
        maxWidth={'lg'}
        disableBackdropClick={true}
        style={{margin: '0 !important'}}
        className={classes.container}
        open={true}
        TransitionComponent={Transition}
        keepMounted
      >
        <Preloader loaded={!loading}>
          <Grid container className={classes.header}>
            <Grid item lg={8} md={8} xs={9} alignItems='center' container>
              <DialogTitle id="form-dialog-title">Расчет стоимости доставки от склада в Москве</DialogTitle>
            </Grid>
            <Grid item lg={4} md={4} xs={3} justify='flex-end' container>
              <IconButton onClick={this.handleClose} className={classes.buttonIcon} aria-label="Close">
                <ClearIcon fontSize="large"/>
              </IconButton>
            </Grid>
          </Grid>
          <Divider/>
          
          <DialogContent className={classes.content}>
            <Typography variant="h6" className={classes.heading}>Адрес и дата доставки</Typography>
            <AddressComponent
              city={city}
              city_fias_id={city_fias_id}
              settlement_fias_id={settlement_fias_id}
              street={street}
              street_fias_id={street_fias_id}
              house={house}
              apartment={apartment}
              handleCity={this.handleCity}
              handleStreet={this.handleStreet}
              handleHouse={this.handleHouse}
              handleApartment={this.handleApartment}
              deliveryDate={deliveryDate}
              deliveryTimeFrom={deliveryTimeFrom}
              deliveryTimeTo={deliveryTimeTo}
              handleDate={this.handleDate}
            />
            <Typography variant="h6" className={classes.heading}>Информация о грузе</Typography>
            <DimensionsComponent
              height={height}
              weight={weight}
              width={width}
              length={length}
              assessedCost={assessedCost}
              codCost={codCost}
              handleChange={this.handleChange}
            />
            {
              index && tariffs.length > 0 && !isUpdated && (
                <React.Fragment>
                  <Divider/>
                    <ExpansionPanel defaultExpanded expanded={true} square>
                      <ExpansionPanelSummary expandIcon={null}>
                        <Typography className={classes.heading}>Варианты доставки</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <DeliveryTypesComponent 
                          tariffs={tariffs}
                          pointTariffs={pointTariffs}
                          handleShowPoints={() => this.setState({showPoints: true, point: {id: null, name: ""}})}
                        />
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                </React.Fragment>
              )
            }
            {
              showPoints && (
                <PointsComponent
                  key={`${point.id}_${pointTariffs.length}`}
                  handleSelect={this.handlePoint} 
                  points={pointTariffs} 
                  showPoints={showPoints}
                  city={city} 
                  point={point}
                />
              )
              
            }
          </DialogContent>
          <Divider/>
          <DialogActions style={{margin: 20}}>
            <Grid container>
              <Grid justify='flex-end' item md={12} sm={12} xs={12} lg={12} container>
                <Button disabled={false} variant='contained' color='primary' onClick={this.handleSubmit} className={classes.button}>
                  Рассчитать
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
          <ToastContainer/>
        </Preloader>
      </Dialog>
    )
  }
}


DialogComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog({breakpoint: 'xs'})(withStyles(styles)(DialogComponent));