export const config = {
  url: "https://api.a1srv.ru/v1/",
};


// Расчет стоимости доставки от склада в Москве + 

// Наложенный платеж + 

// Кнопка Оплата картой/наличными

// Услуга = способ получения + 
// Почта Россия = в отделении почты России + 
// Курьерская доставка = Доставка до адреса получателя (Эконом) + 
// Пункт выдачи=Пункт выдачи заказов + 

// Срок доставки, дней = Срок доставки +

// Доставка до адреса получателя (Экспресс) от 1 часа

// Стоимость, ₽=Стоимость доставки + 

// Стоимость от ...

// Кнопка Подробнее у ПВЗ + 
// Наличными
//  - 

// Коммиссия за прием наличную оплату
// Коммиссия за оплату какртой банка 
// Сумма страховки
// Итого