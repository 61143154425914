import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

const styles = {
  root: {
    flexGrow: 1,
  },
  buttonContainer: {
    height:'100%',
    width: '100%',
    position: 'absolute',
  },
  stylesGrid: {
    backgroundColor: 'red'
  }
};

class Preloader extends Component {
  render() {
    const {loaded, classes, children} = this.props;

    return (
      <React.Fragment>
        <div style={{opacity: loaded ? 1 : 0.3}}>
          {children}
        </div>
        
        {
          !loaded && (
            <Grid container alignItems={'center'} justify={'center'} className={classes.buttonContainer}>
              <Grid item>
                <CircularProgress className={classes.progress} size={50} />
              </Grid>
            </Grid>
          )
        }
      </React.Fragment>

    )
  }
}

Preloader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Preloader);