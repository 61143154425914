import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CityComponent from '../city';
import StreetComponent from '../street';
import HouseComponent from '../house';
import {DatePicker, TimePicker, MuiPickersUtilsProvider} from 'material-ui-pickers';
import 'moment/locale/fr';
import 'moment/locale/ru';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class AddressComponent extends Component {

  render() {
    const {
      city,
      city_fias_id,
      settlement_fias_id,
      street,
      street_fias_id,
      house,
      handleCity,
      handleStreet,
      handleHouse,
      deliveryDate, 
      deliveryTimeFrom, 
      deliveryTimeTo,
      handleDate,
    } = this.props;

    return (
      <Grid container spacing={8}>
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <CityComponent
            city={city}
            onChange={handleCity}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <StreetComponent
            key={`${city}`}
            street={street}
            city_fias_id={city_fias_id}
            settlement_fias_id={settlement_fias_id}
            onChange={handleStreet}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={2}>
          <HouseComponent
            key={street}
            house={house}
            street_fias_id={street_fias_id}
            onChange={handleHouse}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={2}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={'ru'}>
            <DatePicker
              label="Дата"
              name="deliveryDate"
              fullWidth
              variant="outlined"
              value={deliveryDate}
              format={'DD.MM.YYYY'}
              minDate={moment()}
              margin="dense"
              onChange={(date) => handleDate('deliveryDate', date)}
              leftArrowIcon={<ChevronLeftIcon/>}
              rightArrowIcon={<ChevronRightIcon/>}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={4} md={1} lg={1}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={'ru'}>
            <TimePicker
              ampm={false}
              label="С"
              name="deliveryTimeFrom"
              fullWidth
              variant="outlined"
              margin="dense"
              value={deliveryTimeFrom}
              format={'HH:mm'}
              onChange={(time) => handleDate('deliveryTimeFrom', time)}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={4} md={1} lg={1}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={'ru'}>
            <TimePicker
              ampm={false}
              label="По"
              margin="dense"
              name="deliveryTimeTo"
              fullWidth
              variant="outlined"
              value={deliveryTimeTo}
              format={'HH:mm'}
              onChange={(time) => handleDate('deliveryTimeTo', time)}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    )
  }
}

AddressComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddressComponent);