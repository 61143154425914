import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import dadataApi from '../../services/dadata';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 7,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 15,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    overflow: 'none',
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      variant="outlined"
      margin="dense"
      // error={props.getValue().length === 0}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class StreetComponent extends React.Component {
  state = {
    options: [],
  }

  onInputChange = query => {
    const {city_fias_id, settlement_fias_id} = this.props;

    const params = {
      query,
      locations: [{
        city_fias_id,
        settlement_fias_id,
      }],
      from_bound: {
        value: "street"
      }, 
      to_bound: {
        value: "street"
      }, 
      restrict_value: true
    }

    dadataApi('address', params, 'POST')
      .then(json => {
        const result = json.suggestions || [];

        this.setState({
          options: result.map(sug => {
            const {value, data} = sug
            return {
              value,
              label: value,
              index: data.postal_code,
              city: data.city,
              data,
            };
          })
        })
      })
  }

  render() {
    const {options} = this.state;
    const {street, onChange, classes, theme, city_fias_id, settlement_fias_id} = this.props;

    const selectStyles = {
      container: (base, state) => ({
        ...base,
        opacity: state.isDisabled ? ".5" : "1",
        backgroundColor: "transparent",
        zIndex: "999"
      }),
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            classes={classes}
            styles={selectStyles}
            isClearable={true}
            components={components}
            value={street && {value: street, label: street}}
            options={options}
            onInputChange={this.onInputChange}
            onChange={onChange}
            textFieldProps={{
              label: 'Улица',
              InputLabelProps: {
                shrink: true,
              },
            }}
            cacheOptions={true}
            blurInputOnSelectboolean={true}
            isDisabled={!city_fias_id && !settlement_fias_id}
            placeholder="Выберите улицу"
          />
        </NoSsr>
      </div>
    )
  }
}



StreetComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(StreetComponent);
