import React, {Component} from 'react';
import './styles.css';

class PointsComponent extends Component {
  componentDidMount() {
    this.initWidget();
  }

  initWidget = () => {
    const {points, city, handleSelect} = this.props;

    const widgetMap = new window.aWidgetMap('widget_map', {
      search: city,
      points: points.map(point => point.recipient.deliveryPoint),
      disableQuery: true,
      onCancel() {},
      onSelect(_point) {
        const {name, id} = _point;
        // document.getElementById('widget_map').remove()
        handleSelect(id, name);
      }
    });
    widgetMap.init();
  }

  componentWillReceiveProps(nextProps) {
    
    if (nextProps.showPoints && nextProps.point.id === '') {
      alert('fff')
      // this.initWidget();
      document.getElementById('widget_map').hidden = false;
    } else {
      document.getElementById('widget_map').hidden = true;
    }
    // if (nextProps.point.id === '') {
    //   document.getElementById('widget_map').hidden = false;
    // } else {
    //   document.getElementById('widget_map').hidden = true;
    // }
  }
  
  render() {
    return (
      <div id="widget_map"></div>
    )
  }
}

export default PointsComponent;