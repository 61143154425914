export default function calculationParams(params) {
  const {
    index,
    city,
    address,
    weight,
    width, 
    length, 
    height,
    assessedCost,
    codCost,
  } = params;
  
  return {
    calculation: [
      {
        to: {
          deliveryType: "",
          recipient: {
            index,
            city,
            address,
          },
        },
        deliverySalad: false,
        weight,
        width,
        height,
        length,
        codCost,
        assessedCost,
        pickupDate:""
      }
    ]
  }
}