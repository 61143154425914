
export default theme => 
  ({
    root: {
      width: '100%',
    },
    // heading: {
    //   fontSize: theme.typography.pxToRem(15),
    //   fontWeight: theme.typography.fontWeightRegular,
    // },
    header: {
    },
    container: {
      margin: 0,
    },
    content: {
      height: '60vh',
      // paddingTop: 20,
      // padding: 0
    },
    buttonIcon: {
      margin: theme.spacing.unit,
    },
  })